@import "../../variables.scss";

.DonationModal {
  position: fixed;
  display: flex;
  overflow: scroll;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 50%;
  left: -50%;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  transform: translate(-50%, -50%);
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.DonationModal::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.DonationModal {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.Show {
  left: 50%;
}

.Show ~ .Backdrop {
  display: block;
}

.Backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(#000000, 0.85);
}

.NavButtonContainer {
  z-index: 25;
  height: 7rem;
  opacity: 0.85;
  margin-top: 2rem;
}

.CheckoutPanelContainer {
  position: relative;
  width: 100%;
  background-color: $space-gray;
  border-radius: 2rem;
  padding: 2rem 2rem 0rem;
  // margin: 5rem;
  max-width: 30rem;
  max-width: 50rem;
}

.RespectPrivacy {
  color: $rocket-trail-white;
  font-family: "Montserrat", "Tahoma", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  margin-top: -0.5rem;
  padding-bottom: 2rem;
}
