@import "../../../variables.scss";

* {
  text-decoration: none;
}

.Btn {
  cursor: pointer;
}

.SideDrawerButton,
.SideDrawerSubMenuButton {
  position: relative;
  color: $light-yellow;
}

.SideDrawerButton {
  padding: 2rem 10rem 2rem 4rem;
}

.SideDrawerButtonText {
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.SideDrawerButton .SideDrawerButtonText {
  line-height: 6rem;
  font-size: 3rem;
  cursor: pointer;
}

//SUB MENU BUTTONS

.SideDrawerSubMenuButton {
  padding: 2rem 3rem 2rem 6rem;
  cursor: pointer;
}

.SideDrawerSubMenuButton .SideDrawerButtonText {
  //
  line-height: 3rem;
  font-size: 2rem;
}

.MouseOver {
  color: $space-gray;

  transition: color 0.2s 0.2s;

  .Arrow {
    fill: $space-gray;

    transition: fill 0.2s 0.2s, transform 0.2s ease;
  }
}

.MouseDown {
  //   transition: none;
}

.Arrow {
  fill: $light-yellow;
  height: 2rem;
  margin-right: 1rem;
}

.Rotated {
  transform: rotate(90deg);
}

//HOVER EFFECT

.SideDrawerButton:before,
.SideDrawerSubMenuButton:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
  background-color: $primary-blue;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.2s, width 0.2s 0.15s cubic-bezier(1, 0, 0, 1);
  z-index: -1;
}

.MouseOver.SideDrawerButton:before,
.MouseOver.SideDrawerSubMenuButton:before {
  transform: scale(1);
  width: 100%;
}

.MouseDown.SideDrawerButton:before,
.MouseDown.SideDrawerSubMenuButton:before {
  background-color: $light-yellow;
}

.OpenMenu.SideDrawerButton:before {
  transform: scale(1);
  width: 100%;
}

.MouseOverMobile.SideDrawerButton:before,
.MouseOverMobile.SideDrawerSubMenuButton:before {
  transition: none;
  transform: scale(1);
  width: 100%;
}

@media screen and (max-width: 400px) {
  .SideDrawerButton .SideDrawerButtonText {
    font-size: 2rem;
  }

  .SideDrawerSubMenuButton .SideDrawerButtonText {
    font-size: 1.5rem;
  }

  .Arrow {
    height: 1.5rem;
  }
}
