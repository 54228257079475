@import "../../variables.scss";
.BtnBGContainer {
  position: relative;
  width: 100%;
  min-width: 5rem;
  min-height: 5rem;
  max-width: 65rem;
  max-height: 65rem;
}

.BtnBG {
  position: relative;
  display: block;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.BtnText {
  position: absolute;
  color: $light-yellow;
  top: 0;
  left: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.BtnInnerText {
  position: absolute;
  font-size: 10px;
  transition: transform 0.14s cubic-bezier(0.79, 2.02, 0.59, 0.75);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.BtnText1 {
  margin-top: 5%;
}

.BtnText2 {
  margin-top: 2%;
  transform: scale(1.2);
}

.BtnText3 {
}

@media screen and (min-width: 280px) {
  .BtnInnerText {
    font-size: calc(10px + 54 * ((100vw - 280px) / 1720));
  }
}
@media screen and (min-width: 2000px) {
  .BtnInnerText {
    font-size: 64px;
  }
}

.MainSectionBtn {
  cursor: pointer;
  width: 100%;
  height: 100%;
  mask-repeat: no-repeat;
  transition: transform 0.1s ease-out;
}

.BtnInnerTextActive {
  color: $primary-red;
  transform: scale(1.15);
}

.BtnInnerTextMouseDown {
  color: $primary-yellow;
  transform: scale(1.08);
}

.BtnStars {
  position: absolute;
  z-index: 8;
  width: 100%;
  bottom: 0;
  left: 0;
  transition: width 0.1s ease-out, left 0.1s ease-out, bottom 0.1s ease-out;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}

.BtnStarsActive {
  width: 90%;
  left: 5%;
  bottom: 5%;
}

.MainSectionBtn1 {
  transform: scale(1.06);
  mask: url("../../assets/images/svg/main-btn-1.svg");
}

.MainSectionBtn2 {
  transform: scale(0.94);
  mask: url("../../assets/images/svg/main-btn-2.svg");
}

.MainSectionBtn3 {
  transform: scale(0.98);
  mask: url("../../assets/images/svg/main-btn-3.svg");
}

.MainSectionBtn1Active {
  transform: scale(1.15);
  mask: url("../../assets/images/svg/main-btn-1.svg");
}

.MainSectionBtn2Active {
  transform: scale(1.08);
  mask: url("../../assets/images/svg/main-btn-2.svg");
}

.MainSectionBtn3Active {
  transform: scale(1.08);
  mask: url("../../assets/images/svg/main-btn-3.svg");
}

.Nom {
  position: absolute;
  bottom: -7%;
  left: 0;
  transform-origin: bottom;
  transform: scale(0.08, 0);
  transition: transform 0.2s cubic-bezier(0.79, 2.02, 0.59, 0.75);
  z-index: 8;
}

.NomActive {
  transform: scale(0.14);
}
