@import "../../variables.scss";

.EnergyBarChartPage {
  position: relative;
  width: 100%;
  max-width: 2200;
  background-color: $space-gray;
}

.MobileMessage {
  color: $rocket-trail-white;
  position: relative;
  font-family: "Montserrat", "Tahoma", sans-serif;
  font-size: 4rem;
  font-weight: 600;
  padding: 6rem 2rem 12rem;
  text-align: center;
  z-index: 4;
}

.Nom {
  position: absolute;
  bottom: 0;
  max-height: 15rem;
  transform-origin: center bottom;
  transform: translateY(15%);
}

.Heading {
  color: $light-yellow;
  font-family: "CocogoosePro-Regular", sans-serif;
  text-align: center;
  font-size: 6rem;
  margin: 0rem 0 3rem;
  padding: 2rem 1rem 0rem;
}

.Credits {
  color: $rocket-trail-white;
  font-family: "Montserrat", "Tahoma", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.8rem;
  margin-right: 1rem;
  padding: 5rem 0 1rem;
  text-align: right;
}

@media only screen and (max-width: 800px) {
  .Heading {
    margin: 0;
    font-size: 3.2rem;
  }

  .Credits {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }
}
