@import "../../../variables.scss";

.VideoUI {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.VideoContainer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoadingMessage {
  position: absolute;
  color: $light-yellow;
  font-family: "CocogoosePro-Regular", sans-serif;
  font-size: 4rem;
  display: flex;
  background-color: black;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.Video {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.PlayButtonContainer,
.PlayButtonContainerPaused {
  position: absolute;
  display: inline-block;
  width: 100%;
  // height: 100%;
  z-index: 10;
  opacity: 0.5;
  transition: opacity 0.2s ease;
}

.PlayButtonContainerPaused {
  opacity: 0;
}

.PlayButton {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background: #fff;
  transition: clip-path 0.3s ease;
}

.PlayButtonContainerVisible {
  opacity: 0.5;
}

.PlayButtonContainerInvisible {
  opacity: 0;
}

.PlayButtonContainerDisappear {
  // transition-delay: 0.1s;
  opacity: 0;
}

.VideoContainer:hover .PlayButtonContainerDisappear {
  transition-delay: 0s;
  opacity: 0.5;
}

.PlayButtonBefore {
  clip-path: polygon(0 0, 50% 25%, 50% 75%, 0% 100%);
}

.PlayButtonAfter {
  clip-path: polygon(50% 25%, 100% 50%, 100% 50%, 50% 75%);
}

.PlayButtonContainerPaused .PlayButtonBefore {
  clip-path: polygon(0 0, 30% 0, 30% 100%, 0% 100%);
}

.PlayButtonContainerPaused .PlayButtonAfter {
  clip-path: polygon(70% 0, 100% 0, 100% 100%, 70% 100%);
}

.SliderContainer {
  position: relative;
  width: 100%;
  z-index: 10;
}

.InstantDisplay {
  position: absolute;
  left: 50%;
  padding: 0.6rem 1.2rem;
  font-family: "Montserrat", "Tahoma", "sans-serif";
  font-weight: 500;
  font-size: 2.2rem;
  color: $light-yellow;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 2rem;
  white-space: nowrap;
}

.InstantDisplayShowing {
  opacity: 1;
  transition: opacity 0.25s ease;
  z-index: 4;
}

.InstantDisplayHiding {
  opacity: 0;
  transition: opacity 0.35s ease;
  z-index: 9;
}

.InstantLabels {
  position: relative;
  font-family: "Montserrat", "Tahoma", "sans-serif";
  font-weight: 500;
  font-size: 1.8rem;
  color: $light-yellow;
  overflow: visible;
  z-index: 10;
}

.Instant1Label,
.Instant2Label,
.Instant1LabelMobile,
.Instant2LabelMobile {
  position: absolute;
  padding: 0.6rem 1.4rem;
  border-radius: 6rem;
  transition: all 0.3s ease;
  pointer-events: none;
}

.Instant1Label,
.Instant2Label {
  transform: translate(-50%, -124%);
}

.Instant1LabelMobile,
.Instant2LabelMobile {
  transform: translate(-50%, -135%);
}

.Instant1Label {
  padding: 0.3rem 1.1rem;
}
.Instant2Label {
  padding: 0.3rem 1.1rem 0.3rem 1rem;
}

.Instant1Label:hover,
.Instant2Label:hover,
.Instant1LabelHighlighted,
.Instant2LabelHighlighted {
  background-color: $light-yellow !important;
  color: $space-gray;
}

.SystemName {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  // transform: translateY(-50%);
  padding: 0.4rem 0.5rem 0.6rem 0.6rem;
  font-family: "Montserrat", "Tahoma", "sans-serif";
  font-weight: 500;
  font-size: 1.8rem;
  color: $rocket-trail-white;
  background-color: $space-gray;
  opacity: 0.8;
  border-radius: 1rem 0 0 0;
  z-index: 10;
}
