@import "../../../variables.scss";

.MenuDropdown {
  padding: 0.8rem 0;
}

.FaIcon {
  transform: translateY(5%) scale(0.9);
  padding-left: 1rem;
}

.MenuCheck {
  margin-right: 1rem;
}

.Option:hover {
  transform: scale(1.08);

  .OptionX {
    transform: scale(1.25);
  }
}

.CategoryOption {
  background-color: $primary-red;
}

.TopicOption {
  background-color: $primary-blue;
}

.ItemContent {
  display: flex;
  align-items: center;
}

.FaStar {
  margin-right: 0.6rem;
  margin-left: -0.5rem;
  margin-top: -0.4rem;
  color: $primary-yellow;
}


@media screen and (max-width: 600px) {
  .MenuDropdown {
    text-align: center;
  }
}

@media screen and (max-width: 400px) {
  .MenuDropdown {
    width: 100%;
    text-align: center;
  }
}
