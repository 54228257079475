@import "../../../variables.scss";

.EnergyBarChartContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.EnergyBarChart {
  position: relative;
  height: 36rem;
  text-align: center;
  transition: all 0.3s ease;
  margin: 0;
  white-space: nowrap;
}

.BarChartBGLeft,
.BarChartBGRight {
  display: inline-block;
  position: relative;
  min-width: 20rem;
  height: 100%;
  background-color: #fefefe;
  transition: background-color 1s ease;
  padding-left: 2rem;
  padding-right: 2rem;
}

.BarChartBGEmpty {
  min-width: 38rem;
}

.BarChartBGLeft {
  border-radius: 2rem 0 0 2rem;
}

.BarChartBGRight {
  border-radius: 0 2rem 2rem 0;
}

.BarChartBGHighlighted {
  animation: colorFade 1.2s 1;
  transition: none;
}

@keyframes colorFade {
  from {
    background-color: $light-yellow;
  }
  to {
    background-color: #fefefe;
  }
}

.EnergyBars {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
}

.TopHeading {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -125%);
  font-family: "Montserrat", "Tahoma", "sans-serif";
  font-size: 2.2rem;
  color: $light-yellow;
  // font-style: italic;
  font-weight: 500;
  z-index: 1;
}

.StarterMessage {
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $space-gray;
  font-family: "Montserrat", "Tahoma", "sans-serif";
  font-size: 2.5rem;
  white-space: initial;
  font-weight: 600;
}

.FaIconLeft,
.FaIconRight {
  position: absolute;
  font-size: 5rem;
  top: 50%;
  left: 35%;
}

.FaIconLeft {
  left: 0;
  margin-top: -2rem;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: right;
  animation-name: bounce-L;
  animation-timing-function: ease;
}

@keyframes bounce-L {
  0% {
    transform: scale(1, 1) translateX(0);
  }
  10% {
    transform: scale(0.9, 1.1) translateX(0);
  }
  30% {
    transform: scale(1.1, 0.9) translateX(-10px);
  }
  50% {
    transform: scale(1, 1) translateX(0);
  }
  100% {
    transform: scale(1, 1) translateX(0);
  }
}

.FaIconRight {
  right: 0;
  margin-top: -2rem;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: left;
  animation-name: bounce-R;
  animation-timing-function: ease;
}

@keyframes bounce-R {
  0% {
    transform: scale(1, 1) translateX(0);
  }
  14% {
    transform: scale(0.9, 1.1) translateX(0);
  }
  30% {
    transform: scale(1.1, 0.9) translateX(10px);
  }
  50% {
    transform: scale(1, 1) translateX(0);
  }
  100% {
    transform: scale(1, 1) translateX(0);
  }
}

.WorkSection {
  display: inline-block;
  position: relative;
  height: 100%;
  background-color: $space-gray;
  transition: all 0.3s ease;
  cursor: pointer;
  pointer-events: none;
}

.WorkSectionShowing {
  background-color: #ddd;
  pointer-events: initial;
  padding: 0 1rem;
}

.EnergyBarContainer {
  position: relative;
}

.DeleteBtn {
  position: absolute;
  transform: translate(-50%, 0%);
  width: 28px;
  height: 28px;
  top: 100%;
  left: 50%;
  margin-top: 1.4rem;
  color: white;
  border-radius: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Minus {
  width: 56%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 4px solid $rocket-trail-white;
}

.KEDeleteBtn {
  background-color: $primary-green;
}

.KEDeleteBtn:hover {
  background-color: #b5e7c0;
}

.GPEDeleteBtn {
  background-color: $primary-blue;
}

.GPEDeleteBtn:hover {
  background-color: #78d3e6;
}

.EPEDeleteBtn {
  background-color: #f7e25e;
}

.EPEDeleteBtn:hover {
  background-color: #f3ebb4;
}

.IEDeleteBtn {
  background-color: $primary-red;
}

.IEDeleteBtn:hover {
  background-color: #f1927d;
}

.FaIconTrash {
  color: $rocket-trail-white;
  font-size: 3rem;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(110%, 110%);
  transition: all 0.2s ease;
  opacity: 0.5;
  padding: 1rem;
  box-sizing: content-box;
  cursor: pointer;
}

.FaIconTrash:hover {
  transform: translate(110%, 110%) scale(1.08);
  opacity: 1;
}

.FaIconTrash:active {
  transform: translate(110%, 110%) scale(0.98);
  opacity: 1;
}

@media only screen and (max-width: 800px) {
  .BarChartBGLeft,
  .BarChartBGRight {
    min-width: 12rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .TopHeading {
    font-size: 2rem;
  }

  .EnergyBarChart {
    height: 34rem;
  }

  .StarterMessage {
    font-size: 2rem;
  }

  .FaIconLeft,
  .FaIconRight {
    font-size: 3.2rem;
  }

  .DeleteBtn {
    width: 22px;
    height: 22px;
  }

  .Minus {
    width: 60%;
    border-bottom: 3px solid $rocket-trail-white;
  }
}
