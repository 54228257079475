@import "../../variables.scss";

.Button {
  background-color: $primary-red;
  display: inline-block;
  margin: 2rem 0;
  color: $rocket-trail-white;
  padding: 1.5rem 2rem;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 4rem;
  font-family: "Montserrat", "Tahoma", "sans-serif";
  transition: all 0.2s ease;
  backface-visibility: hidden;
  cursor: pointer;
}

.ButtonBlue {
  background-color: $primary-blue;
  margin: -3rem auto;
}

.Button:hover {
  transform: scale(1.1) translateY(-5px);
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.4);
}

.Button:active {
  transform: scale(1.05) translateY(0px);
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.4);
}

@media screen and (min-width: 1500px) {
  .Button {
    margin: 4rem 0;
    padding: 3rem 4rem;
    font-size: 4rem;
    border-radius: 6rem;
  }
}

@media screen and (min-width: 1200px) {
  .Button {
    margin: 3rem 0;
    padding: 2rem 3rem;
    font-size: 3rem;
    border-radius: 4.5rem;
  }
}
