.DragBox {
  touch-action: none;
}

.DragBox:hover {
  background-color: #f0f0f0;
}

.BarContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.Bar {
  z-index: 2;
}

.BarHeading {
  position: absolute;
  top: 3%;
  font-size: 2rem;
  font-weight: 600;
  font-family: "Montserrat", "Tahoma", "sans-serif";
  color: "#222";
  text-align: center;
  width: 100%;
  cursor: help;
}

.HeadingFull {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -150%);
  color: #222;
  background-color: #f0f0f0;
  border: 2px solid #222;
  border-radius: 1.2rem;
  padding: 0.5rem 1rem;
  font-size: 2.2rem;
  font-weight: 600;
  font-family: "Montserrat", "Tahoma", "sans-serif";
}

.Baseline {
  position: absolute;
  border-bottom: 3px solid;
  border-top: none;
  border-left: none;
  border-right: none;
  z-index: 1;
  transform: translateY(-50%);
}

.TickLine {
  position: absolute;
  border-bottom: 1px dashed;
  border-top: none;
  border-left: none;
  border-right: none;
}

.UnitLabel {
  position: absolute;
  font-size: 1.8rem;
  font-weight: 600;
  font-family: "Montserrat", "Tahoma", "sans-serif";
  z-index: 5;
}

.AlertIcon {
  position: absolute;
  top: 28.6%;
  font-size: 2.4rem;
  font-weight: 600;
  font-family: "Montserrat", "Tahoma", "sans-serif";
  color: #f3775b;
  z-index: 5;
  padding: 3rem;
  box-sizing: initial;
  cursor: initial;
}

.AlertText {
  position: absolute;
  bottom: 56%;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 2rem;
  width: 100%;
  white-space: normal;
  font-family: "Montserrat", "Tahoma", "sans-serif";
  color: #fff;
  background-color: #f3775b;
  z-index: 5;
  padding: 1rem 0.5rem;
  pointer-events: none;
  z-index: 8;
}

@media only screen and (max-width: 800px) {
  .BarHeading {
    font-size: 1.8rem;
  }

  .UnitLabel {
    font-size: 1.4rem;
  }
}
