@import "../../../../variables.scss";

.CheckModalContainer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-width: 800px;
  height: 100%;
  z-index: 48;
}

.CheckModalBG {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background-color: black;
  opacity: 0.5;
  cursor: pointer;
}

.CheckModal {
  position: absolute;
  overflow: scroll;
  width: 80%;
  height: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $light-yellow;
  font-family: "Montserrat", "Tahoma", sans-serif;
  padding-top: 3rem;
  border-radius: 2rem;
}

.CloseButton {
  position: absolute;
  color: $primary-red;
  top: 0;
  left: 0;
  margin-top: 0rem;
  padding-left: 1.2rem;
  font-family: sans-serif;
  font-size: 5rem;
  cursor: pointer;
  z-index: 11;
}

.CloseButton:hover {
  font-weight: 600;
}

.CloseButton:active {
  transform: scale(0.9);
}

.ErrorHeading,
.ErrorSubheading {
  position: relative;
  color: $space-gray;
  font-family: "Montserrat", "Tahoma", sans-serif;
  text-align: center;
  font-size: 4.5rem;
  font-weight: 600;
  z-index: 10;
}

.ErrorSubheading {
  margin-top: 0.5rem;
  font-size: 1.8rem;
  font-weight: 500;
}

.HeadingDivider {
  width: 90%;
  margin: 2rem auto 1rem;
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 2px solid $space-gray;
}

.ErrorElements {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.ErrorElement {
  max-width: 60rem;
  background-color: #9168ca;
  color: $rocket-trail-white;
  font-family: "Montserrat", "Tahoma", sans-serif;
  font-size: 1.8rem;
  border-radius: 2rem;
  // transition: all 0.2s ease;
  margin: 2rem;
}

.ErrorElementCategory {
  padding: 0.8rem 1.5rem 0.4rem;
  font-weight: 500;
  display: inline-block;
}

.ErrorDivider {
  width: 95%;
  margin: auto;
  text-align: center;
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 1px solid $rocket-trail-white;
}

.ErrorElementHint {
  padding: 1rem 1.5rem;
  color: transparent;
  text-shadow: 0 0 8px rgba(255, 255, 255, 1);
  transition: all 0.5s ease;
}

.ErrorElementHint:hover {
  text-shadow: 0 0 0 rgba(255, 255, 255, 1);
}

.FaInfoCircle {
  display: inline-block;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: -0.4rem;
}

.FaInfoCircle:hover {
  transform: scale(1.08);
}

.WinContainer {
  display: flex;
  align-items: center;

  position: relative;
  width: 100%;
  height: 85%;
}

.Video {
  position: relative;
  width: 50%;
  height: 100%;
  margin: 4rem 3rem 4rem 6rem;
}

.WinGraphicsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  padding: 2rem 3rem 2rem 3rem;
  width: 50%;
  height: 100%;
}

.SystemDisplay {
  display: flex;
  align-items: center;
}

.IconContainer {
  position: relative;
  display: inline-block;
}

.FaSquare {
  color: $primary-blue;
  font-size: 6rem;
  position: relative;
}

.FaCheck {
  color: $rocket-trail-white;
  font-size: 4rem;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(14%, 28%);
}

.FaCheckCurrent {
  color: $rocket-trail-white;
  font-size: 4rem;
  position: absolute;
  left: 0;
  top: 0;
  animation: slam 0.4s;
  transform: translate(14%, 28%) scale(1);
}

.FaStar {
  color: #ffd76a;
  font-size: 6rem;
  position: absolute;
  right: 0;
  top: 0;
  margin: 2rem;
  text-shadow: 5px 5px 3px #000;
  animation: slamStar 0.4s;
  // transform: translate(14%, 28%);
}

@keyframes slam {
  0% {
    transform: translate(14%, 28%) scale(5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  85% {
    transform: translate(14%, 28%) scale(0.94);
  }
  100% {
    transform: translate(14%, 28%) scale(1);
  }
}

@keyframes slamStar {
  0% {
    transform: scale(4);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  85% {
    transform: scale(0.94);
  }
  100% {
    transform: scale(1);
  }
}

.SystemName {
  color: $space-gray;
  font-family: "Montserrat", "Tahoma", sans-serif;
  font-size: 2.4rem;
  padding: 2rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.SystemName:hover {
  color: $primary-blue;
  transform: scale(1.06);
}

@media only screen and (max-width: 800px) {
  .CheckModalContainer {
    width: 100%;
    min-width: 100%;
    left: 0;
    top: 0;
    transform: none;
  }

  .CheckModalBG {
    width: 100%;
    height: 100%;
  }

  .CheckModal {
    width: 100%;
  }

  .ErrorHeading {
    font-size: 2.8rem;
  }

  .ErrorSubheading {
    font-size: 1.2rem;
  }

  .WinContainer {
    flex-direction: column;
    align-items: center;

    position: relative;
    height: 85%;
  }

  .WinGraphicsContainer {
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    width: 90%;
  }

  .SystemDisplay {
    display: flex;
    align-items: center;
  }
}
