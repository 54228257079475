@import "../../variables.scss";

.SiteNav {
  position: relative;
  box-sizing: border-box;
  z-index: 20;
}

.HeaderBar {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 320px;
  height: 8vw;
  min-height: 6.5rem;
  max-height: 8.5rem;
  transform: translateY(0);
  transition: transform 0.3s ease;
  z-index: 15;
  pointer-events: none;
  transition: transform 0.2s ease;
}

.Hidden {
  transform: translateY(-180%);
}

.Shadow {
  background-color: $space-gray;
}

.Shadow .AmpersandLogo {
  visibility: visible;
}

.Hidden .AmpersandLogo {
  visibility: hidden;
}

.AmpersandLogo {
  position: absolute;
  visibility: hidden;
  width: 10%;
  height: 80%;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 12;
  cursor: pointer;
  pointer-events: initial;
  fill: $light-yellow;
  transition: transform 0.24s ease;
}

.AmpersandLogo:hover {
  fill: $primary-yellow;
  transform: translateX(-50%) scale(1.1);
}
