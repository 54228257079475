@import "../../../variables.scss";

.BarChartGame {
  position: relative;
  width: 100%;
  background-color: $space-gray;
}

.ProgressDisplay1,
.ProgressDisplay2 {
  color: $rocket-trail-white;
  padding: 1rem;
  margin-left: 2rem;
  font-size: 2.7rem;
  font-family: "Montserrat", "Tahoma", sans-serif;
  display: flex;
}

.FaCheck {
  color: $primary-blue;
  margin-right: 1.1rem;
}

.FaStar {
  color: $primary-yellow;
  margin-left: -0.2rem;
  margin-right: 0.8rem;
}

.FiltersTop {
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 10rem;
  justify-content: space-around;
  display: flex;
}

.Scenario {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ScenarioTop {
  display: flex;
  align-items: center;
}

.System {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SystemTop {
  display: flex;
  align-items: center;
}

.ScenarioDescription,
.SystemDescription {
  text-align: center;
  font-family: "Montserrat", "Tahoma", sans-serif;
  margin-top: 2rem;
}

.ScenarioDescription {
  color: $rocket-trail-white;
  background-color: rgba($color: #000000, $alpha: 0.3);
  // border: 2px solid $rocket-trail-white;
  padding: 1rem 2rem;
  margin-left: 3rem;
  margin-right: 3rem;
  border-radius: 3rem;
  font-size: 2.6rem;
}

.SystemDescription {
  color: $rocket-trail-white;
  background-color: rgba($color: #000000, $alpha: 0.3);
  padding: 1rem 2rem;
  margin-left: 3rem;
  margin-right: 3rem;
  border-radius: 3rem;
  font-size: 2.6rem;
  // background-color: $primary-blue;
}

.UITop {
  display: flex;
  justify-content: space-evenly;
  // width: 100%;
}

.ScenarioText {
  color: $primary-red;
}

.SystemText {
  color: $primary-blue;
}

.SystemName {
  position: relative;
  font-family: "Montserrat", "Tahoma", "sans-serif";
  font-size: 2rem;
  color: $light-yellow;
  font-weight: 500;
  margin: auto;
  text-align: center;
}

.Digit {
  display: inline-block;
  transition: all 0.5s ease;
}

.DigitHighlighted {
  transition: none;
  color: $primary-yellow;
}

.InfoMessage {
  margin: 1rem auto;
  width: 80%;
  text-align: center;
  color: $rocket-trail-white;
  font-family: "Montserrat", "Tahoma", sans-serif;
  font-size: 2.8rem;
  line-height: 4rem;
}

.TopButton:active {
  transform: scale(0.99);
}

.CheckButton {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -175%);
  font-size: 2rem;
  border-radius: 1.4rem;

  border: none;
  padding: 1.2rem;
  text-decoration: none;
  color: $rocket-trail-white;
  font-size: 2.6rem;
  font-family: "CocogoosePro-Regular", "Montserrat", "Tahoma", sans-serif;
  border-radius: 1.4rem;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #519760;
  cursor: pointer;
}

.CheckButton:hover,
.CheckButton:focus {
  background: $primary-green;
}

.CheckButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.HelpButton {
  position: absolute;
  bottom: 0;
  left: 8%;
  background: $primary-blue;
  cursor: pointer;
  display: inline-block;
  border: none;
  padding: 0.8rem 1.4rem 0.9rem;
  text-decoration: none;
  color: $rocket-trail-white;
  font-size: 2.2rem;
  font-family: "CocogoosePro-Regular", "Montserrat", "Tahoma", sans-serif;
  border-radius: 1.1rem;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.HelpButton:hover,
.HelpButton:focus {
  background: #73d7ff;
}

.MainContent {
  position: relative;
  display: flex;
  justify-content: space-around;
  margin-top: 8rem;
}

.VideoWrapper,
.BCWrapper {
  position: relative;
}
.BCWrapper {
  align-items: flex-start;
  margin-left: 1.8rem;
}

@media only screen and (max-width: 1400px) {
  .MainContent {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  .CheckButton {
    position: relative;
    align-self: center;
    transform: none;
    left: 0;
    margin-top: 2rem;
    margin-bottom: 5rem;
  }

  .VideoWrapper {
    align-self: center;
  }

  .BCWrapper {
    align-self: center;
    margin-left: 1.8rem;
    margin-bottom: 5rem;
  }

  .FiltersTop {
    margin-top: 0rem;
    margin-bottom: 5rem;
  }
}

@media only screen and (max-width: 800px) {
  .InfoMessage {
    width: 95%;
    margin: 2rem auto;
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .FiltersTop {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .VideoWrapper {
    margin-top: 2rem;
    width: 80%;
  }

  .ScenarioTop,
  .SystemTop {
    flex-direction: column;
  }

  .ScenarioDescription,
  .SystemDescription {
    font-size: 1.8rem;
    margin-top: 1rem;
  }

  .ProgressDisplay1,
  .ProgressDisplay2 {
    font-size: 2rem;
  }

  .BC {
    position: relative;
    display: flex;
  }
}

@media only screen and (max-width: 500px) {
  .BCWrapper {
    align-self: flex-start;
  }
}
