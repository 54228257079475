@import "../../../variables.scss";

.WorkButton {
  position: absolute;
  padding: 0.8rem 0.6rem;
  cursor: pointer;
  border-radius: 1.6rem;
  text-align: center;
  color: $space-gray;
  background-color: $light-yellow;
  font-size: 2rem;
  font-family: "CocogoosePro-Regular", "Montserrat", "Tahoma", sans-serif;
  transition: all 0.2s ease;
  top: 105%;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: initial;
}

.WorkButton:hover {
  background-color: $rocket-trail-white;
}

@media only screen and (max-width: 800px) {
  .WorkButton {
    padding: 0.6rem 0.4rem;
    font-size: 1.6rem;
    border-radius: 1rem;
  }
}
