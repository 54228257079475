@import "../../../variables.scss";

.SideDrawer {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: $space-gray;
  z-index: 10;
  transform: translateX(-110%);
  transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  padding-top: 8.6rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.SideDrawer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.SideDrawer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.Show {
  transform: translateX(0);
}

.Show ~ .CloseButton {
  display: block;
}

.CloseButton {
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(#000000, 0.6);
  cursor: pointer;
}

.TopMask {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $space-gray;
  transform: translateX(-100%);
  transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  height: 80px;
  width: 37.4rem;
  z-index: 14;
}

.TopMaskShow {
  transform: translateX(0);
}

//FONT AWESOME GAME ICONS
.FaIcon {
  margin-right: 1rem;
}

.SocialIcons {
  padding-left: 3rem;
  font-size: 4rem;
}

.SocialIcon {
  padding: 1rem 1rem;
  color: $light-yellow;
  text-decoration: none;
  box-sizing: content-box;
}

.SocialIcon:hover {
  color: $primary-blue;
}

.Separator {
  color: $light-yellow;
  text-align: center;
  margin: 5rem 0;
}

.ContactUs {
  margin: auto;
}

.ContactLink {
  text-decoration: none;
}

.ContactLink:hover {
  color: $primary-blue;
}

.DonationFooter {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 6rem;
  font-size: 3rem;
  text-align: center;
  width: 100%;
  margin-top: 3rem;
  background-color: $primary-red;
  padding: 3rem 1.5rem 4rem;
  box-sizing: border-box;
  cursor: pointer;
}

.DonationFooter:hover {
  background-color: white;
  color: $primary-red;
}

.DonateLink {
  text-decoration: none;
}

.SideDrawerButton {
  text-decoration: none;
}

@media screen and (max-width: 650px) {
  .SideDrawer {
    width: 100%;
  }

  .TopMask {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .SocialIcons {
    font-size: 3rem;
  }

  .DonationFooter {
    font-size: 2rem;
  }
}
